import React, { useState } from "react";
import useNotifications from "../../hooks/use-notifications";
import Spinner from "../ui/spinner";
import Avatar from "@atlaskit/avatar";
import Comment, {
    CommentAction,
    CommentAuthor,
    CommentEdited,
    CommentTime,
} from "@atlaskit/comment";
import { Link } from "react-router-dom";
import Pagination from "@atlaskit/pagination";
import styled from "styled-components";
import slugify from "slugify";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import apiClient from "../../api/api-client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function DirectNotifications({ module = null, highlight = true }) {
    const [page, setPage] = useState(1);
    const { data, isPending, pagination, reload, isSuccess } = useNotifications("direct", module, page);
    const { t } = useTranslation();

    const anyUnread = data?.some(i => !i?.read_at);

    const removeAll = () => {
        apiClient("notifications", { method: "DELETE" })
            .then(reload)
            .catch(() => { });
    };

    const readAll = () => {
        localStorage.removeItem("counted");
        localStorage.removeItem("counted-date");
        apiClient("notifications/read-all", { method: "POST" })
            .then(reload)
            .catch(() => { });
    };

    const readOne = (id) => {
        localStorage.removeItem("counted");
        localStorage.removeItem("counted-date");
        apiClient(`notification/${id}/read`, { method: "POST" })
            .then(reload)
            .catch(() => { });
    };

    if (isPending && data?.length === 0) return <Spinner />;

    return <>
        <NotificationButtonsWrapper>
            <Spacer />
            {!(isSuccess && data?.length === 0) && (
                <div>
                    {anyUnread
                        ? <NotificationButton onClick={readAll}>{t("direct_notifications_read_all")}</NotificationButton>
                        : <NotificationButton onClick={removeAll}>{t("direct_notifications_remove_all")}</NotificationButton>
                    }
                </div>
            )}
        </NotificationButtonsWrapper>
        {isSuccess && data?.length === 0 && <div>
            <h3>{t("direct_notifications_empty_heading")}</h3>
            <p>{t("direct_notifications_empty_p")}</p>
        </div>}
        {data.map(notification => {
            return (
                <NotificationItem key={notification.id} notification={notification} onRemoved={reload} onRead={() => readOne(notification.id)} />
            );
        })}
        {pagination && !(isSuccess && data?.length === 0) && <PaginationWrapper>
            <Pagination onChange={(e, p) => setPage(p)} selectedIndex={page - 1}
                pages={Array.from(Array(Math.ceil(pagination?.total / pagination?.per_page)).keys(), (_, i) => i + 1)} />
        </PaginationWrapper>}
    </>;
}

function NotificationItem({ notification, onRemoved, onRead }) {
    const [hover, setHover] = useState(false);
    const [loading, setLoading] = useState(false);
    const remove = () => {
        setLoading(true);
        apiClient(`notifications/${notification.id}`, { method: "DELETE" })
            .then(onRemoved)
            .catch(() => { });
    };

    return <NotificationWrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Notification notification={notification} isLoading={loading} onRead={onRead} />
        {hover && <NotificationRemoveButtonWrapper onClick={() => remove()}>
            &times;
        </NotificationRemoveButtonWrapper>}
    </NotificationWrapper>;
}

const NotificationButtonsWrapper = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

const NotificationButton = styled.div`
    padding: 3px 0px 10px;
    display: inline-block;
    margin-left: 10px;
    font-size: 0.9em;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
        text-decoration: underline;
    }
`;

const Spacer = styled.div`
    flex: 1;
`;

const NotificationWrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
`;

const NotificationRemoveButtonWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 20px;
    font-weight: 500;
    opacity: 0.7;
    cursor: pointer;
    margin-left: 10px;
    padding: 10px;
`;

function Notification({ notification, isLoading = false, onRead }) {
    return <Comment
        css={{ paddingTop: 0 }}
        highlighted={!notification.read_at}
        avatar={<NotificationLink notification={notification} onRead={onRead}>
            <Avatar src={notification?.from_user?.avatar_url ?? notification?.avatar_url} size="medium" />
        </NotificationLink>}
        author={<NotificationLink notification={notification} onRead={onRead}>
            <CommentAuthor>{notification?.from_user?.first_name + " " + notification?.from_user?.last_name}</CommentAuthor>
        </NotificationLink>}
        time={<NotificationLink notification={notification} onRead={onRead}>
            <CommentTime>{(new Date(notification?.created_at)).toLocaleString()}</CommentTime>
        </NotificationLink>}
        content={<NotificationLink notification={notification} onRead={onRead}>
            <NotificationMessage notification={notification} />
        </NotificationLink>}
        afterContent={notification?.document && <NotificationLink notification={notification} onRead={onRead}>
            <DocumentDetails document={notification?.document} />
        </NotificationLink>}
    />;
}

function NotificationMessage({ notification }) {
    let message = formatNotification(notification);

    if (notification?.document === null && message.includes("{name}")) {
        return <DocumentRemovedNotification>
            Dokument usunięty.
        </DocumentRemovedNotification>;
    }



    return <p dangerouslySetInnerHTML={{ __html: message }} />;
}

function NotificationLink({ notification, children, onRead }) {
    const document = notification?.document;

    if (document) {
        const { module, space, category, id } = document;
        return <Link to={`/${category?.module?.name}/${category?.slug ?? slugify(category?.name)}/${space.slug}/${id}`.toLowerCase()} key={notification.id} style={{ color: "#172B4D" }} onClick={onRead}>
            {children}
        </Link>;
    }

    return children;
}

function formatNotification(notification) {
    let message = notification?.message || "";

    if (notification.document) {
        const name = notification.document?.values?.find(i => i.name === "name")?.value;
        message = message.replaceAll("{name}", "<strong>" + name + "</strong>");
    }

    if (notification.private_message) {
        const privateMess = `<blockquote>${notification.private_message}</blockquote>`;
        message = message + privateMess;
    }

    return message;
}

export default DirectNotifications;

const PaginationWrapper = styled.div`
    padding: 20px;
`;

function DocumentDetails({ document }) {
    const name = document?.values?.find(i => i.name === "name")?.value;

    return <DocumentWrapper>
        <DocumentDetailsIcon>
            <Page16Icon />
        </DocumentDetailsIcon>
        <DocumentDetailsName>
            {name}
        </DocumentDetailsName>
    </DocumentWrapper>;
}

const DocumentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DocumentDetailsIcon = styled.div`
    padding-top: 4px;
`;

const DocumentDetailsName = styled.div`
    flex-grow: 1;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DocumentRemovedNotification = styled.div`
    padding: 5px 10px;
    background: #eee;
    border-radius: 5px;
    margin: 0;
    font-size: 1em;
    opacity: 0.5;
    margin-right: 40px;
`;